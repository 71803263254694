import StaffMemberModel from 'Models/rh/StaffMemberModel';
import Avatar           from 'antd/lib/avatar';
import { AvatarProps }  from 'antd/lib/avatar';
import Space            from 'antd/lib/space';
import Spin             from 'antd/lib/spin';
import Tooltip          from 'antd/lib/tooltip';
import _omit            from 'lodash/omit';
import { observer }     from 'mobx-react';
import { when }         from 'mobx';
import { Link }         from 'react-router-dom';
import React            from 'react';
import { blobToBase64 } from 'tools/blobTools';
import navigate         from 'tools/navigate';

export const cacheSrc: Record<id, string | undefined> = {};

export default observer((props: AvatarProps & {
	link?: boolean | string;
	mode?: 'photo' | 'initials' | 'fullName';
	staffMember: StaffMemberModel;
	tooltipDisabled?: boolean;
}) => {
	const { link, mode, staffMember, tooltipDisabled } = props;

	const isInitials = mode === 'initials';
	const isFullName = mode === 'fullName';

	const [src, setSrc] = React.useState(isInitials ? undefined : cacheSrc[staffMember.id] || '');

	React.useEffect(() => {
		if (!isInitials) {
			when(() => !!staffMember.get('staffMemberPhoto.id'), () => {
				if (!cacheSrc[staffMember.id]) {
					staffMember.photoReadModel
						.setId(staffMember.get('staffMemberPhoto.id'))
						.fetch()
				}
			});
		}

		if (!staffMember.isLoaded && !staffMember.isLoading && staffMember.id) {
			staffMember.fetch().catch(() => null);
		}
	}, []);

	React.useEffect(() => {
		if (!isInitials && staffMember.photoReadModel.blob) {
			blobToBase64(staffMember.photoReadModel.blob, base64 => setSrc(base64 as string));
		}
	}, [staffMember.photoReadModel.blob]);

	cacheSrc[staffMember.id] = src;

	const photoReadCrashed = !staffMember.photoReadModel.isLoading && staffMember.photoReadModel.isDirty;
	let content = (
		<Avatar
			src={src || undefined}
			{..._omit(props, ['staffMember', 'link', 'mode', 'tooltipDisabled'])}
			style={{
				backgroundColor: staffMember.color,
				border: src ? `2px solid ${staffMember.color}` : undefined,
				outline: photoReadCrashed ? '2px solid red' : undefined,
				color: photoReadCrashed ? 'red' : undefined,

				...props.style,
			}}
		>
			{(!isInitials && staffMember.photoReadModel?.id && !src && !photoReadCrashed) ?
				<Spin size="small" /> :
				(photoReadCrashed ?
					'err'
					: staffMember.initials
				)
			}
		</Avatar>
	);

	if (isFullName) {
		content = (
			<Space style={{ whiteSpace: 'nowrap' }}>
				{content}

				{staffMember.fullName || '...'}
			</Space>
		);
	}

	if (link) {
		const href = (typeof link === 'string') ? link : navigate.toStaffMember(staffMember.id);

		content = <Link target="_blank" to={href}>{content}</Link>;
	}

	if (!tooltipDisabled) {
		content = <Tooltip placement="top" title={staffMember.fullName}>{content}</Tooltip>;
	}

	return content;
});